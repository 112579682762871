import React from "react";
import Seo from "../../components/Seo";
import Certifications from "../../views/Formation/Quality_2022/Certifications";
import QualityHero from "../../views/Formation/Quality_2022/Hero";
import Indicators from "../../views/Formation/Quality_2022/Indicators";
import Testimonials from "../../views/Formation/Quality_2022/Testimonials";

const QualityPage = () => {
  return (
    <React.Fragment>
      <Seo
        title="Qualité de nos formations"
        description="Innovance Solutions est certifié Qualiopi pour ses activités de formation et s'inscrit dans une démarche de qualité continue autour de ses logiciels de gestion pour associations."
      />
      <QualityHero />
      <Indicators />
      <Testimonials />
      {/* <Certifications /> */}
    </React.Fragment>
  );
};

export default QualityPage;
